export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {}
    },
    errors: {
      type: [Object, Array],
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function(newVal) {
        this.$emit('input', newVal);
      }
    }
  }
};
