<template>
    <div>
        <small class="p-invalid block mt-2" v-for="message in errors" :key="message">
            {{ message }}
        </small>
    </div>
</template>

<script>
    export default {
        props: {
            errors: {
                type: [Object, Array],
                required: true,
                default: () => []
            }
        }
    }
</script>
