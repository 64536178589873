<template>
    <div>
        <div class="field grid mb-4">
            <label class="col-fixed text-700 mt-1 w-4">
                Interne Bezeichnung
            </label>
            <div class="col">
                <InputText class="w-full" v-model="value['internalName']"/>
                <errors :errors="errors['internalName']"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Value from "@/mixin/Value";
    import Errors from "@/components/form/Errors";
    import InputText from "primevue/inputtext/InputText";

    export default {
        components: {Errors, InputText},
        mixins: [Value],
    }
</script>
